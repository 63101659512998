<template>
  
    
    <div class="row">
      <div class="col-12">
             
        
        <section>     

          <h1>Terms & Conditions</h1>

          <p><b>Last Updated: December 28, 2022</b></p>




          <p>We are constantly developing the content of this website and strive to provide accurate and up-to-date information. Unfortunately, we cannot assume any liability for the correctness of all content on this website, especially for that provided by third parties. As a service provider, we are not obliged to monitor the information transmitted or stored by you or to investigate circumstances that indicate illegal activity. Our obligations to remove information or to block the use of information according to the general laws due to judicial or official orders remain unaffected by this, even in the case of our non-responsibility.</p>


        
          <p>All contents of this website are subject to copyright. Please consult us before you distribute, reproduce or exploit the content of this website such as republishing on other websites.</p>
          <p>
            © The copyright lies with the IVES Project Team or the respective authors of the papers.
          </p>
          
          University of Applied Sciences Upper Austria <br/>
          Softwarepark 13 <br/>
          4232 Hagenberg im Mühlkreis<br/>
          Austria

          <br/><br/>
          
          <b>Projekt Lead:</b><a href="mailto:mandy.keck@fh-hagenberg.at"> Mandy Keck</a><br/>
          <b>Design & Development:</b> Lisa Freller<br/>
          <b>IVES Project Team:</b>Lisa Freller, Mandy Keck, Mirjam Augstein, Thomas Neumayr<br/>
          <b>Facet Browsing Code:</b> initialy by Sara R. on <a target="_blank" href="https://github.com/sara-luise/bachelorThesisFindPatterns" alt="Github" title="Github">Github</a>
          
       
          

<div class="row d-flex align-items-center partner-logo">
<div class="col-sm d-flex  justify-content-end">
<a href="https://www.fh-ooe.at/">
<img src="@/assets/resources/FHO_FE_Logo_DEEPS.png"  alt="Logo Research and Development" class="kit-img"/>
</a>
</div>
<div class="col-sm justify-content-center">
<a href="https://peec.fh-hagenberg.at/projects/projectdetail/25?cHash=ffd4938aca440775735045b02a580c41">
<img src="@/assets/resources/LogoPEEK.png"  alt="Peek Logo" class="kit-img"/>
</a>
</div>
</div>
        </section>


        <hr/>
        
        
        <section>

          <h3>Terms of Service</h3>


          <p>In the following Privacy Policy, we will inform you on if and how we evaluate the data of your visit to this website. The evaluation is generally made anonymously, and we cannot link to you personally based on your behaviour on this website.</p>

          <p>
            All texts on the homepage have been carefully checked. Nevertheless, no guarantee can be given for the correctness, completeness and topicality of the information. Any liability is therefore excluded. The links to other websites have been carefully selected but no responsibility is taken for them.
          </p>

          <p>
            Our website contains links to other websites for whose content we are not responsible. We are not liable for linked websites, as we had and have no knowledge of any illegal activities, we have not noticed any such illegalities so far and we would remove links immediately if we became aware of any illegalities.
          </p>

        </section>

        <hr/>

        <section>
          <h3>Privacy policy</h3>
          <p>
            We have written this privacy statement (version 01/18/2023-122395340) to explain to you, in accordance with the requirements of the <a target="_blank" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=122395340#d1e2269-1-1" alt="Regulation" title="Regulation">General Data Protection Regulation (EU) 2016/679 </a> and applicable national laws, which personal data (data for short) we as the controller - and the processors (e.g. providers) commissioned by us - process, will process in the future and what lawful options you have. The terms used are to be understood as gender-neutral. We inform you comprehensively about data that we process about you.
          </p>
          <p>
            To protect personal data, we have implemented both technical and organizational measures. Where possible, we encrypt or pseudonymize personal data. In this way, we make it as difficult as possible for third parties to infer personal information from our data.
          </p>
          <p>
            Article 25 of the GDPR refers to "data protection through technical design and data protection-friendly default settings" and thus means that both software (e.g., forms) and hardware (e.g., access to the server room) should always be designed with security in mind and that appropriate measures should be taken. If necessary, we will go into more detail on specific measures below.
          </p>
          <p>
            When you contact us, personal data may be processed.
          </p>
      
          
        </section>

        <hr/>

          <section>
            <h3>Cookies</h3>
          <p>
            Almost all websites use cookies. HTTP cookies are small files that are stored on your computer by our website. These cookie files are automatically placed in the cookie folder, effectively the "brain" of your browser. A cookie consists of a name and a value. When defining a cookie, one or more attributes must also be specified.
          </p>
          <p>
            Cookies store certain user data about you, such as language or personal page settings. When you return to our site, your browser transmits the "user-related" information back to our site. Thanks to cookies, our site knows who you are and offers you the setting you are used to. In some browsers, each cookie has its own file, in others, such as Firefox, all cookies are stored in a single file.
          </p>
          <p>
            The storage period depends on the particular cookie. Some cookies are deleted after less than an hour, others can remain stored on a computer for several years.
          </p>

          <p>In case of further questions, please send E-Mail to <a href="mailto:mandy.keck@fh-hagenberg.at">Mandy Keck</a></p>


        </section>



      </div>
    </div>

</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped lang="scss">

section {
  padding-bottom:1vh;
  // padding-top:3vh;
}

  div.partner-logo div a img {
    width:11rem;
  }

  // hr {
  //   margin: 2vh 0 2vh 0;

  // }

</style>
