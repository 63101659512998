<template>
  <div class="error-page">
       <div class="row">
      <div class="col-12">
        <h1>404</h1>
        <h2>page not found.</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped lang="scss">
.error-page {
  margin: 5rem;
  height: 10rem;

  h1 {
   
    align-self: center;
    
  }

}
</style>
