<template>
  <div class="heatmap-viz">
  <iframe width="83%" height="1084" frameborder="0"
  src="https://observablehq.com/embed/@fhooe-infovis/heatmap_ives?cells=chart"></iframe>

</div>

 
</template>



<script>

export default {
  name: "HeatmapVisualization",
  data() {
    return {
      
      listShown: false,
    };
  },
  methods: {
   
   
  }, 
  computed: {
    
  }
};

</script>

